import { React } from 'react'
import { Typography, Row, Layout } from 'antd';

const { Content } = Layout;
const { Title } = Typography;
const titleStyle = {
    textAlign: 'center',
    color: 'red',
};
const subtitleStyle = {
    color: 'red',
};

const contentStyle = {
    textAlign: 'left',
    backgroundColor: '#ffffff',
    textAlign: 'Justify'
};

function PoliticaDevoluciones() {
    return (
        <Content style={contentStyle}>
            <Title level={2} style={titleStyle} >Garantías y Devoluciones</Title>
            <Title level={4} style={subtitleStyle} >
                LA GARANTÍA
              </Title>
              <Title level={5}>
                Todos los productos de APYMSA son nuevos y cuentan con garantía directa del proveedor, la cual será válida únicamente cuando el producto presente fallas atribuibles a la manufactura, no al uso o una incorrecta instalación.
                <br></br>
                La garantía tiene una vigencia de 90 días.
              </Title>
              <Title level={4} style={subtitleStyle}>
                LA DEVOLUCIÓN
              </Title>
              <Title level={5}>
                La generación de la guía de envío y la gestión administrativa son responsabilidad de APYMSA, siempre y cuando la causa sea una acción no atribuible al cliente, es decir, errores en el surtido de la orden, defectos de fabricación o daño en el envío. Si no es el caso, será cubierta por el cliente.
                En cuanto se presente una incidencia (solicitud de devolución o garantía), es necesario ingresar a apymsa.com.mx e identificar la sección de Devoluciones y garantías.
                Una vez en módulo de atención, el cliente debe relacionar el pedido y factura. En cuanto el cliente inicie sesión como cliente de apymsa.com.mx, la solicitud se pondrá a consideración.
                Si la factura está en rango de atención, se procede. De lo contrario, termina el proceso.
                En caso de que la solicitud sea exitosa será necesario (con carácter obligatorio) que el cliente indique lo siguiente:
                <br></br>
                <ol>
                  <li>Motivo de la devolución o garantía.</li>
                  <li>Descripción por escrito del problema.</li>
                  <li>Adjuntar evidencia fotográfica de lo antes descrito.</li>
                  <li>La relación de la compra con la factura.</li>
                </ol>
                <br></br>
                En cuanto toda la información sea ingresada y validada, se hará entrega de una guía de devolución a través de la plataforma de atención, el cliente empacará y enviará el producto para que sea revisado por el personal autorizado de apymsa.com.mx
                con el retorno del producto y estar en orden la solicitud de devolución o garantía, se procede al reembolso.
                <br></br>
                <ol>
                  <li>Si el pago fue hecho en efectivo, se solicitarán los datos bancarios al cliente para hacer la transferencia.</li>
                  <li>Si el pago fue hecho con tarjeta, el reembolso se hará a la misma.</li>
                </ol>
                <br></br>
                En cualquier caso, todas las transacciones en pagos.apymsa.com.mx, se harán a través de la plataforma Openpay.
              </Title>
        </Content>
    )
}

export default PoliticaDevoluciones;