import { React, useState, useRef, useEffect } from 'react'
import { Row, Col, Card, Divider, Typography, Image } from 'antd';
import escudoIcono from '../img/EscudoIcono.svg';
import CajaIcono from '../img/CajaIcono.svg';
import { ListaProductos } from './ListaProductos.jsx';
import '../styles/resumenpago.css';

const { Title } = Typography;

/**
 * Componente para mostrar el resumen del pago (subtotal, productos, total)
 * @param {data, style, title} ResumenPago 
 * @returns vista con diseño de resumen del pago
 */
export const ResumenPago = ({ data, style, title }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [time, setTime] = useState(false);
    const [textmodal, setTextModal] = useState();
    const [titlemodal, setTitleModal] = useState();
    const [btncambiartarjeta, btnCambiarTarjeta] = useState();
    const [datalog, dataLog] = useState(data);
    const btnPago = useRef();

    const imgStyles = {
        width: '30px',
        height: '30px',
        paddingBottom: ''
    };

    const styleprecio = {
        textAlign: 'right',
        padding: '0px'
    };

    const styleIconsFooter = {
        aling: 'center',
        paddingTop: '20px'
    };

    const styleDivider = {
        paddingBottom: '0px',
        paddingTop: '0px'
    };

    const [styleResumen, setStyleResumen] = useState({
        overflow: "",
        height: '320px',
        scrollbarGutter: "stable"
    })


    const returnClick = async () => {
        window.location.reload();
    }

    const timerLoadCar = async () => {
        setTime(true);
        const a = setTimeout(() => {
            setTime(false);
        }, 3000);
        return true;
    }

    useEffect(() => {
        timerLoadCar();

    }, []);

    
    if (datalog.data[2].length > 4) {
        styleResumen.overflow = "auto"
    } else {
        styleResumen.overflow = ""
    }
    return (
        <Card style={style}
            title={title ? title.title : ''}
            bordered={true}
            loading={time}
            actions={[
                <Row>
                    <Col style={styleIconsFooter} className="gutter-row" xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} lg={{ span: 5, offset: 5 }} >
                        <Image
                            preview={false}
                            src={escudoIcono}
                            style={imgStyles}
                        />
                    </Col>
                    <Col className="gutter-row" xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} lg={{ span: 8 }} >
                        <p>Garantía 90 días</p>
                    </Col>
                </Row>,
                <Row>
                    <Col style={styleIconsFooter} className="gutter-row" xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} lg={{ span: 5, offset: 3 }} >
                        <Image
                            preview={false}
                            src={CajaIcono}
                            style={imgStyles}
                        />
                    </Col>
                    <Col className="gutter-row" xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} lg={{ span: 10 }} >
                        <p>Devolución 30 días</p>
                    </Col>
                </Row>
            ]}
        >
            <Col style={styleResumen} className="gutter-row" xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} lg={{ span: 24, order: 2 }} >
                <ListaProductos productos={datalog.data[2]} />
            </Col>
            <Divider style={styleDivider} />
            <Row className='resumenTotal'>
                <Col className="gutter-row" xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 13 }} >
                    <Title level={5}>TOTAL: </Title>
                </Col>
                <Col style={styleprecio} className="gutter-row" xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 11 }} >
                    <Title level={5} >${datalog.data ? datalog.data[1].monto : 0} </Title>
                    <p style={styleprecio}>MXN/IVA incluido</p>
                </Col>
            </Row>
        </Card>
    )
}
