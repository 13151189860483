import React from 'react';
import { Modal, Layout, Grid } from 'antd';
import { useState } from 'react';


/**
 * Componente para mostrar modales del footer
 * @param {setModalFooterOpen,modalFooterOpen,contentModal} ModalFooter 
 * @returns vista con diseño modal
 */
function ModalFooter({ setModalFooterOpen, modalFooterOpen, contentModal }) {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  let [modalwidth, setModalWidth] = useState(1100);

  async function responsiveSize(screens) {
    let screennow;
    {
      Object.entries(screens)
        .filter((screen) => !!screen[1])
        .map((screen) => (
          screennow = screen[0]
        ))
    }
    switch (screennow) {
      case "xxl":
        modalwidth = 1100;
        break;
      case "xl":
        modalwidth = 1000;
        break;
      case "lg":
        modalwidth = 800;
        break;
      case "md":
        modalwidth = 600;
        break;
      case "sm":
        modalwidth = 500;
        break;
      case "xs":
        modalwidth = 400;
        break;

      default:
        break;
    }
  }

  responsiveSize(screens);

  return (
    <>
      <Modal
        title=""
        centered
        open={modalFooterOpen}
        onCancel={() => setModalFooterOpen(false)}
        width={modalwidth}
        footer={[]}
      >
        <Layout>
          {contentModal}
        </Layout>
      </Modal>
    </>
  );

}

export default ModalFooter;


