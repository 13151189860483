import { useState } from 'react';
import { Layout } from 'antd';
import AvisoPrivacidad from '../others/AvisoPrivacidad';
import ModalFooter from './ModalFooter';
import PoliticaDevoluciones from '../others/PoliticaDevoluciones';
import TerminosCondiciones from '../others/TerminosCondiciones';


/**
 * Componente para mostrar el footer de toda la aplicación
 * @param {footerStyle} Footer 
 * @returns vista con diseño de Footer
 */
export const Footer = ({ footerStyle }) => {
  const { Footer } = Layout;

  const divStyle = {
    background: 'linear-gradient(270deg, #1a3992 0%, #e6002b 100%)',
    height: '2px',
    transform: 'matrix(-1, 0, 0, 1, 0, 0)',
    margin: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
  }
  
  const [modalFooterOpen, setModalFooterOpen] = useState(false);
  const [contentModal, setContentModal] = useState();

  const funcionModales = (modalContent) => {
    setModalFooterOpen(true);  
    setContentModal(modalContent);
  }

const yearFooter = new Date();


  return (
    <Footer style={footerStyle}>
      <p> Av. Juan Gil Preciado 4051-A, Col. Hogares de Nuevo México, C.P. 45184, Zapopan, Jalisco, México.
        <br />
        Derechos Reservados APYMSA {yearFooter.getFullYear()}, consulta nuestro 
        <a onClick={() => funcionModales(<AvisoPrivacidad></AvisoPrivacidad>)} > Aviso de privacidad</a> | 
        <a onClick={() => funcionModales(<PoliticaDevoluciones></PoliticaDevoluciones>)} > Política de devoluciones</a> |  
        <a onClick={() => funcionModales(<TerminosCondiciones></TerminosCondiciones>)} > Terminos y condiciones</a>.
      </p>
      <div style={divStyle}></div>
      <ModalFooter setModalFooterOpen={setModalFooterOpen} modalFooterOpen={modalFooterOpen} contentModal={contentModal}></ModalFooter>
    </Footer>
  )
}
