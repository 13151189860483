import { React, useEffect, useRef, useState } from 'react'
import { RegistrarTarjetaForm } from '../components/RegistrarTarjetaForm'
import { ResumenPago } from '../components/ResumenPago'
import { Row, Col, Card, Image, Grid, Collapse, Typography, ConfigProvider, Button } from 'antd';
import { DatosTarjeta } from '../components/DatosTarjeta'
import mastercard from '../img/Mastercard_b1.png';
import visa from '../img/Visa.png';
import americanexpress from '../img/Amex.png';
import logoOpenPay from '../img/openpay_color.png';
import secureOpenPay from '../img/secure_openpay.png';
import NavBar from '../components/NavBar';
import { crearToken } from '../api/openPay';
import ModalPayment from '../components/ModalPayment';
import { Footer } from '../components/Footer';
import { validOpenpayError } from '../utils/helper';
import { getDataLigas } from '../api/pagoPasarela';

const { Title } = Typography;
/**
 * Componente principal de pasarela de pago liga validada
 * @param {data} PasarelaPago 
 * @returns componente JSX
 */
const PasarelaPago = (data) => {
  const [current, setCurrent] = useState(0);
  const [formValues, setformValues] = useState({
    nombreTarjeta: '',
    numeroTarjeta: '',
    fechaTarjeta: '',
    ccv: ''
  });
  const [formtemp, setFormTemp] = useState();
  const [activetoggle, setactiveToggle] = useState();
  const [botonPagoActive, setBotonPagoActive] = useState(true);
  const [cardType, setCardType] = useState('APYMSA_2020_bco_21');
  const [token, setToken] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [time, setTime] = useState(false);
  const [textmodal, setTextModal] = useState(false);
  const [textModalValidate, setTextModalValidate] = useState('');
  const [titlemodal, setTitleModal] = useState();

  const collapseResumen = useRef();
  const cardResumen = useRef();
  const btnAgregaTarjeta = useRef();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const cardresume = {
    overflow: 'visible',
    position: 'relative',
    zIndex: '1'
  };

  const collapseStyle = {
    position: 'absolute',
    width: '100%',
  };

  const titleStyle = {
    top: '0px',
    textAling: 'left',
    alignContent: 'left',
    position: 'auto',
    zIndex: '-1'
  };

  const stylesForm = {
    textAling: 'left',
    alignContent: 'left'
  };

  const rowFooterStyle = {
    bottom: '0px',
    position: 'absolute',
    width: '100%',
    marginTop: '0px'
  };

  const containerStyle = {
    position: 'absolute',
    maxwidth: '1200px',
    maxHeight: '1456px',
    alingcontent: 'center'
  };
  const divresponsive = {
    position: 'relative',
    display: 'grid',
    placeItems: 'center',
    aling: 'center',
    top: '0',
    bottom: '0',
    minHeight: '750px',
    height: '100vh'
  };

  const rowPrincipalStyle = {
    minWidth: '1100px',
    paddingBottom: '',
    paddingTop: '',
    maxWidth: '100%'
  }

  const stylesNavBar = {
    navBarHeigth: '70px',
    iconsNavbarWidth: '200px',
    iconsNavbarHeigth: '200px',
    stepsWidth: '25px',
    stepsHeigth: '25px',
    stepsSize: '',
    stepsCurrent: 0
  };

  const stylesCard = {
    maxWidth: '400',
  };

  const stylesResumen = {
    boxShadow: '400px',
    borderRadius: '0px'
  };

  const resumeTitle = {
    title: 'Resumen de productos'
  };

  const footerStyle = {
    textAlign: 'center',
    color: 'gray',
    position: 'absolute',
    width: '100%',
    marginTop: '30px',
    paddingLeft: '0px',
    paddingRight: '0px',
  };

  const collapseprovider = {
    headerBg: '#ffffff'
  };

  const cardPrincipalStyle = {

    width: '98%',
    boxShadow: '1px 10px 10px 1px rgba(90, 116, 148, 0.3)',
    borderRadius: '0px',
    height: 'auto',
    zIndex: ''
  };

  const cardBodyStyle = {
    width: '99%',
    borderRadius: '0px',
    height: 'auto',
  };

  const btnPagarStyle = {
    marginTop: '',
    padding: '10px',
    position: 'relative',
  };

  const [btnPagarStyles, setBtnPagarStyles] = useState({
    border: 'none',
    background: 'linear-gradient(360deg, #1a3992 0%, #386bc4 100%)',
    borderRadius: '35px',
    fontWeight: '600',
    fontSize: '19px',
    fontStyle: 'bold',
    textAlign: 'center',
    height: '40px',
    letterspacing: '.911538px',
    color: '#fff',
    zIndex: '1'
  });

  async function responsiveSize(screens) {
    let screennow;
    {
      Object.entries(screens)
        .filter((screen) => !!screen[1])

        .map((screen) => (
          screennow = screen[0]
        ))
    }

    switch (screennow) {
      case "xxl":
        containerStyle.top = '2%';
        stylesNavBar.navBarHeigth = '65px';
        stylesNavBar.iconsNavbarWidth = '200px';
        stylesNavBar.iconsNavbarHeigth = '200px';
        stylesNavBar.stepsSize = 'large';
        stylesNavBar.paddingImg = '0px';

        rowPrincipalStyle.minWidth = '1200px';
        cardPrincipalStyle.width = '98%';

        collapseResumen.current.hidden = true;
        cardResumen.current.hidden = false;
        resumeTitle.title = 'Resumen de productos';

        btnPagarStyle.marginTop = '-80px';
        btnPagarStyle.marginRight = '17px';

        divresponsive.minHeight = '560px';
        rowFooterStyle.marginTop = '0px';
        rowFooterStyle.bottom = '0px';

        break;
      case "xl":

        containerStyle.top = '2%';
        stylesNavBar.navBarHeigth = '65px';
        stylesNavBar.iconsNavbarWidth = '200px';
        stylesNavBar.iconsNavbarHeigth = '200px';
        stylesNavBar.stepsSize = 'large';
        stylesNavBar.paddingImg = '0px';
        titleStyle.paddingTop = '0px';
        rowPrincipalStyle.minWidth = '1100px'
        cardPrincipalStyle.width = '98%';
        collapseResumen.current.hidden = true;
        cardResumen.current.hidden = false;
        resumeTitle.title = 'Resumen de productos';
        btnPagarStyle.marginTop = '-80px';
        btnPagarStyle.marginRight = '17px';
        rowFooterStyle.marginTop = '0px';
        rowFooterStyle.bottom = '0vh';
        divresponsive.minHeight = '560px';

        break;
      case "lg":

        containerStyle.top = '2%';
        stylesNavBar.navBarHeigth = '65px';
        stylesNavBar.iconsNavbarWidth = '200px';
        stylesNavBar.iconsNavbarHeigth = '200px';
        stylesNavBar.stepsSize = 'large';
        stylesNavBar.paddingImg = '10px';
        titleStyle.paddingLeft = '40px';
        rowPrincipalStyle.minWidth = '900px'
        cardPrincipalStyle.width = '100%';
        collapseResumen.current.hidden = false;
        cardResumen.current.hidden = true;
        resumeTitle.title = '';
        stylesResumen.margin = '0px';
        stylesResumen.padding = '0px';
        stylesResumen.boxShadow = '0px';
        collapseprovider.headerBg = '#ffffff';
        btnPagarStyle.marginTop = '0px';
        btnPagarStyle.marginRight = '0px';
        rowFooterStyle.marginTop = '0px';
        rowFooterStyle.bottom = '0px';
        divresponsive.minHeight = '560px';

        break;
      case "md":

        containerStyle.top = '2%';
        stylesNavBar.navBarHeigth = '100px';
        stylesNavBar.iconsNavbarWidth = '150px';
        stylesNavBar.iconsNavbarHeigth = '150px';
        stylesNavBar.stepsSize = 'small';
        stylesNavBar.paddingImg = '10px';
        titleStyle.paddingTop = '50px';
        titleStyle.paddingLeft = '40px';
        rowPrincipalStyle.minWidth = '750px'
        cardPrincipalStyle.width = '100%';
        cardResumen.current.hidden = true;
        collapseResumen.current.hidden = false;
        resumeTitle.title = '';
        btnPagarStyle.marginRight = '0px';
        rowFooterStyle.marginTop = '0px';
        rowFooterStyle.bottom = '0px';
        divresponsive.minHeight = '860px';

        break;
      case "sm":

        containerStyle.top = '2%';
        stylesNavBar.navBarHeigth = '100px';
        stylesNavBar.iconsNavbarWidth = '150px';
        stylesNavBar.iconsNavbarHeigth = '150px';
        stylesNavBar.stepsSize = 'small';
        stylesNavBar.paddingImg = '10px';
        titleStyle.paddingTop = '50px';
        btnPagarStyle.marginTop = '0px';
        rowPrincipalStyle.minWidth = '570px';
        cardPrincipalStyle.width = '100%';
        cardResumen.current.hidden = true;
        collapseResumen.current.hidden = false;
        resumeTitle.title = '';
        rowFooterStyle.marginTop = '0px';
        btnPagarStyle.marginRight = '0px';
        rowFooterStyle.bottom = '0px';
        divresponsive.minHeight = '860px';

        break;
      case "xs":

        stylesNavBar.navBarHeigth = '100px';
        stylesNavBar.iconsNavbarWidth = '150px';
        stylesNavBar.iconsNavbarHeigth = '150px';
        stylesNavBar.stepsSize = 'small';
        stylesNavBar.paddingImg = '10px';
        titleStyle.paddingLeft = '0px';
        rowPrincipalStyle.minWidth = '340px';
        cardPrincipalStyle.width = '100%';
        cardResumen.current.hidden = true;
        collapseResumen.current.hidden = false;
        resumeTitle.title = '';
        btnPagarStyle.marginRight = '0px';
        rowFooterStyle.marginTop = '0px';
        rowFooterStyle.bottom = '0px';
        btnPagarStyle.marginTop = '0px';
        divresponsive.minHeight = '860px';

        break;

      default:
        break;
    }
  }
  responsiveSize(screens)

  const divStyle = {
    background: 'linear-gradient(270deg, #1a3992 0%, #e6002b 100%)',
    height: '2px',
    transform: 'matrix(-1, 0, 0, 1, 0, 0)'
  }

  const onClickCollapse = async () => {
    cardPrincipalStyle.zIndex == '1px' ? cardPrincipalStyle.zIndex = '-1px' : cardPrincipalStyle.zIndex = '1px';
     btnPagarStyles.zIndex == '1' ? setBtnPagarStyles({...btnPagarStyles,zIndex:'-1'}) : setBtnPagarStyles({...btnPagarStyles,zIndex:'1'});
  }

  const validError = async () => {
    if (textModalValidate) {
      let respuestaError = await validOpenpayError(textModalValidate);
      if (respuestaError != false) {
        setTitleModal("¡Error!");
        setTextModal(respuestaError);
        btnAgregaTarjeta.current.disabled = false;
      }
    }
  }

  /**
   * Agrega texto de error al modal
   * @returns boolean
   */
  const asingModalError = async () => {
    if (textmodal != false) {
      setModalOpen(true);
      btnAgregaTarjeta.current.disabled = false;
      return true
    }
  }

  useEffect(() => {
    validError();
  }, [textModalValidate]);


  useEffect(() => {
    asingModalError();
  }, [textmodal]);

  const handleClick = async () => {
    let responseDataLiga = await getDataLigas(data.data[0].id);
    if (responseDataLiga.data.data.estatusId === 2) {
      window.location.reload();
    } else if (responseDataLiga.data.data.estatusId === 3 || responseDataLiga.data.data.estatusId === 4) {
      window.location.reload();
    } else {
      setTextModal(false);
      //debugger
      timerLoadCar();
      var responseToken = await crearToken(formValues, setTextModalValidate, data);
      btnAgregaTarjeta.current.disabled = true;
      if (responseToken == false) {
        btnAgregaTarjeta.current.disabled = false;
        setTextModal("Datos de tarjeta invalidos, por favor intenta nuevamente");
        setTitleModal("¡Error!");
        setModalOpen(true);
      }
    }
  }

  const timerLoadCar = async () => {
    setTime(true);
    const a = setTimeout(() => {
      setTime(false);
    }, 5000);
    return true;
  }

  useEffect(() => {
    timerLoadCar();
  }, []);

  return (
    <div className="App">
      <ModalPayment setModalOpen={setModalOpen} modalOpen={modalOpen} textmodal={textmodal} titlemodal={titlemodal}></ModalPayment>
      <NavBar stylesNavBar={stylesNavBar} sucursal={data} />
      <div style={divStyle}></div>
      <div style={divresponsive}>
        <div className="container" style={containerStyle}>

          <Row style={rowPrincipalStyle}>
            <Col style={stylesForm} xs={{ span: 24, order: 3 }} sm={{ span: 24, order: 3 }} md={{ span: 24, order: 3 }} lg={{ span: 24, order: 3 }} xl={{ span: 17, order: 2 }} xxl={{ span: 17, order: 2 }}>
              <Title level={3} style={titleStyle}>  Ingresa los datos de la tarjeta</Title>
              <br />
              <Row>
                <Card style={cardPrincipalStyle} bodyStyle={cardBodyStyle} loading={time}>
                  <br></br>
                  <Row>
                    <Col xs={{ span: 24, offset:0 }} sm={{ span: 16, offset: 4 }} md={{ span: 12, offset: 7 }} lg={{ span: 11, offset: 0 }} xl={{ span: 13, offset: 0 }} xxl={{ span: 13, offset: 0 }}>
                      <DatosTarjeta ReceiveData={formValues} activeToggle={activetoggle} cardType={cardType} stylesCard={stylesCard}></DatosTarjeta>
                      <Row >
                        <Col className="gutter-row"
                          xs={{ span: 1, offset: 5 }} sm={{ span: 1, offset: 6 }} md={{ span: 1, offset: 6 }} lg={{ span: 1, offset: 7 }} xl={{ span: 1, offset: 6 }} xxl={{ span: 1, offset: 6 }}
                        >
                          <br></br>
                          <Image
                            preview={false}
                            width={40}
                            // height={30}
                            alt='center'
                            src={mastercard}
                          />
                        </Col>
                        <Col className="gutter-row" xs={{ span: 1, offset: 5 }} sm={{ span: 1, offset: 3 }} md={{ span: 1, offset: 4 }} lg={{ span: 1, offset: 3 }} xl={{ span: 1, offset: 3 }} xxl={{ span: 1, offset: 3 }}>
                          <br></br>
                          <Image
                            preview={false}
                            width={60}
                            alt='center'
                            src={visa}
                          />
                        </Col>
                        <Col className="gutter-row" xs={{ span: 1, offset: 5 }} sm={{ span: 1, offset: 4 }} md={{ span: 1, offset: 4 }} lg={{ span: 1, offset: 4 }} xl={{ span: 1, offset: 4 }} xxl={{ span: 1, offset: 4 }}>
                          <br></br>
                          <Image
                            preview={false}
                            width={48}
                            // height={30}
                            src={americanexpress}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 13 }} xl={{ span: 11 }} xxl={{ span: 11 }}>
                      <br></br>
                      <br></br>
                      <RegistrarTarjetaForm
                        formValues={formValues}
                        setformValues={setformValues}
                        setactiveToggle={setactiveToggle}
                        setCurrent={setCurrent}
                        setBtnPagoActive={setBotonPagoActive}
                        setToken={setToken}
                        setCardType={setCardType}
                        setFormTemp={setFormTemp}
                      >
                      </RegistrarTarjetaForm>
                      <Row>
                        <Col className='gutter-row' xs={{ span: 1, offset: 0 }} sm={{ span: 2, offset: 0 }} md={{ span: 2, offset: 0 }} lg={{ span: 3, offset: 1 }} xl={{ span: 3, offset: 1 }} xxl={{ span: 3, offset: 1 }}>
                          <Image
                            preview={false}
                            style={{ paddingTop: 12 }}
                            width={55}
                            height={60}
                            alt='center'
                            src={secureOpenPay}
                          />
                        </Col>
                        <Col className='gutter-row' xs={{ span: 7, offset: 3 }} sm={{ span: 6, offset: 1 }} md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 0 }} xl={{ span: 6, offset: 0 }} xxl={{ span: 6, offset: 0 }}>

                          <p
                            style={{ fontSize: 9, paddingTop: 8 }}
                          >Tus pagos se realizan de forma segura con encriptación de 256 bits.</p>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 1, offset: 3 }} sm={{ span: 1, offset: 8 }} md={{ span: 1, offset: 9 }} lg={{ span: 2, offset: 5 }} xl={{ span: 2, offset: 4 }} xxl={{ span: 2, offset: 4 }}>

                          <Image
                            preview={false}
                            style={{ paddingTop: 10 }}
                            width={148}
                            alt='center'
                            src={logoOpenPay}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Row>
            </Col>
            <Col style={cardresume} xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 24, order: 2 }} lg={{ span: 24, order: 2 }} xl={{ span: 7, order: 3 }} xxl={{ span: 7, order: 3 }} >
              <ConfigProvider
                theme={{
                  token: {
                    colorBgContainer: 'white',
                    colorBorder: '#D8D8D8',
                    borderRadiusLG: 0,
                    colorFillAlter: collapseprovider.headerBg,
                  },

                  components: {
                    Collapse: {
                      headerBg: collapseprovider.headerBg,
                    }
                  }
                }}
              >
                <Collapse
                  size="large"
                  style={collapseStyle}
                  onChange={onClickCollapse}
                  items={[{
                    key: '1', label: 'Resumen de productos', children:
                      <ResumenPago
                        data={data}
                        style={stylesResumen}
                        formvalues={formValues}
                        token={token}
                        btnPagoActive={botonPagoActive}
                        formtemp={formtemp}
                      >
                      </ResumenPago>
                  }]}
                  ref={collapseResumen}
                />
              </ConfigProvider>
              <Col ref={cardResumen}>
                <ResumenPago
                  data={data}
                  formvalues={formValues}
                  token={token}
                  btnPagoActive={botonPagoActive}
                  formtemp={formtemp}
                  style={stylesResumen}
                  title={resumeTitle}
                >
                </ResumenPago>
              </Col>
            </Col>
          </Row>

          <Row style={btnPagarStyle}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5, offset: 19 }} xl={{ span: 4, offset: 13, order: 4 }} xxl={{ span: 4, offset: 13, order: 4 }} >
              <Button type="primary" ref={btnAgregaTarjeta} style={btnPagarStyles} onClick={handleClick} block>
                Pagar
              </Button>
            </Col>
          </Row>
        </div >
        <div style={rowFooterStyle}>
          <Row >
            <Col xs={{ span: 24, order: 5 }} sm={{ span: 24, order: 5 }} md={{ span: 24, order: 5 }} lg={{ span: 24, order: 5 }} xl={{ span: 24, order: 5 }} xxl={{ span: 24, order: 5 }}>
              <Footer footerStyle={footerStyle}></Footer>
            </Col>
          </Row>
        </div>
      </div>
    </div >
  )
}

export default PasarelaPago