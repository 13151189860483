import { React, useState, useRef, useEffect } from 'react';
import { Row, Col, Form, Input, Tooltip } from 'antd';
import ModalPayment from './ModalPayment';

/**
 * Componente formulario para registrar datos de la tarjeta
 * @param {idLiga} RegistrarTarjetaForm 
 * @returns vista con diseño de formulario y validaciones
 */
export const RegistrarTarjetaForm = ({ formValues, setformValues, setFormTemp, setactiveToggle, setCurrent, setBtnPagoActive, setToken, setCardType }) => {
    let newOpenPay = window.OpenPay;
    const [isDisabled, setIsDisabled] = useState(false);
    /**state para pintar de rojo el borde del input como error */
    const [errorNombreTarjeta, seterrorNombreTarjeta] = useState('');
    const [errorNumeroTarjeta, seterrorNumeroTarjeta] = useState('');
    const [errorFechaTarjeta, seterrorFechaTarjeta] = useState('');
    const [errorCcv, seterrorCcv] = useState('');
    /** state de mensajes de error de los inputs del formuralio */
    const [errorNombreTarjetaMsg, setErrorNombreTarjetaMsg] = useState({});
    const [errorNumeroTarjetaMsg, setErrorNumeroTarjetaMsg] = useState({});
    const [errorFechaTarjetaMsg, setErrorFechaTarjetaMsg] = useState({});
    const [errorCcvMsg, setErrorCcvMsg] = useState({});
    /** */
    /** Inicia states de Modal **/
    const [modalOpen, setModalOpen] = useState(false);
    const [textmodal, setTextModal] = useState();
    const [titlemodal, setTitleModal] = useState();
    /** Finaliza states de Modal **/
    const inputNombreTarjeta = useRef();
    const inputNumeroTarjeta = useRef();
    const datepickertarjeta = useRef();
    const inputCcv = useRef();
    const btnAgregaTarjeta = useRef();

    const rowStyle = {
        marginBottom: '20px',
        width: 'auto'
    }
    let [toValidInput, setToValidInput] = useState({
        min: 19,
        max: 19
    });
    let [toValidCvv, setToValidCvv] = useState({
        min: 3,
        max: 3
    });

    const handleBlurNombreTarjeta = (event) => {
        validateInput(event);
        let errors = {}
        let form = formValues;
        errors.nombreTarjeta = 'Nombre del titular es requerido'
        setErrorNombreTarjetaMsg(errors);

        if (!form.nombreTarjeta.trim()) {
            errors.nombreTarjeta = 'Nombre del titular es requerido'
            setErrorNombreTarjetaMsg(errors);
        } else {
            delete errors.nombreTarjeta;
            setErrorNombreTarjetaMsg(errors);
        }
    }

    const handleBlurNumeroTarjeta = (event) => {
        validateInput(event);
        let errors = {}
        let form = formValues;

        if (!form.numeroTarjeta.trim()) {
            errors.numeroTarjeta = 'Número de tarjeta es requerido'
            setErrorNumeroTarjetaMsg(errors);

        } else if (!newOpenPay.card.validateCardNumber(form.numeroTarjeta)) {
            errors.numeroTarjeta = 'Número de tarjeta no es valido'
            setErrorNumeroTarjetaMsg(errors);
        } else {
            delete errors.numeroTarjeta;
            setErrorNumeroTarjetaMsg(errors);
        }
    }

    const handleBlurFechaTarjeta = (event) => {
        validateInput(event);
        let expiration = [];
        let errors = {}
        let form = formValues;
        if (form.fechaTarjeta.trim() !== '') {
            expiration = form.fechaTarjeta.split('/');
        }

        if (!form.fechaTarjeta.trim()) {
            errors.fechaTarjeta = 'Fecha vencimiento es requerido'
            setErrorFechaTarjetaMsg(errors);
        } else if (!newOpenPay.card.validateExpiry(expiration[0] ?? 0, expiration[1] ?? 0)) {
            errors.fechaTarjeta = 'Fecha vencimiento no valido'
            setErrorFechaTarjetaMsg(errors);
        } else {
            delete errors.fechaTarjeta
            setErrorFechaTarjetaMsg(errors);
        }
    }

    const handleBlurCcvTarjeta = (event) => {
        validateInput(event);
        let errors = {}
        let form = formValues;

        if (!form.ccv.trim()) {
            errors.ccv = 'CVV es requerido'
            setErrorCcvMsg(errors);
        } else if (!(form.ccv.trim().length > toValidCvv.min - 1)) {
            errors.ccv = 'CVV invalido'
            setErrorCcvMsg(errors);
        } else {
            delete errors.ccv;
            errors.ccv = ''
            setErrorCcvMsg(errors);
            event.target.name === 'ccv' ? setactiveToggle(false) : setactiveToggle(true);
        }
    }


    const validateInput = (event) => {
        let { name, value } = event.target;
        switch (name) {
            case "nombreTarjeta":
                value.length > 0 === true ? seterrorNombreTarjeta('') : seterrorNombreTarjeta('error');
                setformValues({
                    ...formValues,
                    [event.target.name]: value
                });
                break;
            case "numeroTarjeta":
                const valorInput = value.replace(/ /g, "");
                setformValues({
                    ...formValues,
                    [event.target.cvv]: ""
                });
                if (value.length > 4) {
                    let cardType = newOpenPay.card.cardType(value);
                    if (cardType !== '') {
                        switch (cardType) {
                            case "American Express":
                                toValidInput.min = 18;
                                toValidInput.max = 18;
                                toValidCvv.min = 4;
                                toValidCvv.max = 4;
                                break;

                            default:
                                toValidInput.min = 19;
                                toValidInput.max = 19;
                                toValidCvv.min = 3;
                                toValidCvv.max = 3;
                                break;
                        }
                        setCardType(cardType);
                    }
                }

                let inputNumbersOnly = valorInput.replace(/\D/g, "");
                if (inputNumbersOnly.length >= toValidInput.min - 3) {
                    seterrorNumeroTarjeta('');
                    inputNumbersOnly = inputNumbersOnly.substr(0, toValidInput.min - 3);
                } else {
                    seterrorNumeroTarjeta('error');
                }
                const splits = inputNumbersOnly.match(/.{1,4}/g);
                let spacedNumber = "";
                if (splits) {
                    spacedNumber = splits.join(" ");
                }

                //Se agrega el valor formateado en el input
                setformValues({
                    ...formValues,
                    [event.target.name]: spacedNumber
                });

                break;
            case "fechaTarjeta":
                const valor = value.replace(/ /g, "");
                let soloNumeros = valor.replace(/\D/g, "");

                if (soloNumeros.length > 7) {
                    soloNumeros = soloNumeros.substr(0, 7);
                }
                const formato = soloNumeros.match(/.{1,2}/g);
                let splitedNumber = "";
                if (formato) {
                    splitedNumber = formato.join("/");
                }
                value.length > 3 === true ? seterrorFechaTarjeta('') : seterrorFechaTarjeta('error');
                setformValues({
                    ...formValues,
                    [event.target.name]: splitedNumber
                });
                break;
            case "ccv":
                const valorCcv = value.replace(/ /g, "");
                let soloNumerosCcv = valorCcv.replace(/\D/g, "");
                value.length > toValidCvv.min - 1 === true ? seterrorCcv('') : seterrorCcv('error');
                setformValues({
                    ...formValues,
                    [event.target.name]: soloNumerosCcv
                });
                break;
        }
        /**Activar boton */
        if (activeBtnAgregarTarjeta(formValues)) {
            //btnAgregaTarjeta.current.disabled = false;
        } else {
            //btnAgregaTarjeta.current.disabled = true;
        }
    }

    const handleOnFocus = (event) => {
        event.target.name === 'ccv' ? setactiveToggle(true) : setactiveToggle(false);
    }
    const activeBtnAgregarTarjeta = (formValues) => {
        let activo = false;
        if (formValues.nombreTarjeta.length > 15 && formValues.numeroTarjeta.length > 18
            && formValues.fechaTarjeta.length > 3 && formValues.ccv.length > 2) {
            activo = true;
        }
        return activo;
    }

    const resetForm = () => {
        setformValues({
            nombreTarjeta: '',
            numeroTarjeta: '',
            fechaTarjeta: '',
            ccv: ''
        });
    }

    const handleClick = async () => {
        formValues.ccv = "";
    }


    const styleInput = {
        borderRadius: '0px'
    }

    // useEffect(() => {
    //     const btnDisabled = () => {
    //         //btnAgregaTarjeta.current.disabled = true;
    //     }
    //     btnDisabled()
    // }, [])

    const validateMessages = {
        required: '${label} es requerido',
    };

    const text = <span>Ingresa el nombre del titular </span>;
    return (
        <Form
            id="payment-form"
            name="nest-messages"
            validateMessages={validateMessages}
            style={{ display: 'block' }}
        >
            <Form.Item style={rowStyle} className='nombre' rules={[{ required: true, min: 1, message: '${label} Ingresa nombre de tarjeta' }]}>
                {/* <Tooltip placement="top" title={errorNombreTarjetaMsg.nombreTarjeta}> */}
                    <Input
                        status={errorNombreTarjeta}
                        size='large'
                        name='nombreTarjeta'
                        placeholder='Nombre del titular'
                        maxLength={100}
                        ref={inputNombreTarjeta}
                        onChange={validateInput}
                        value={formValues.nombreTarjeta}
                        disabled={isDisabled}
                        onFocus={handleOnFocus}
                        onBlur={handleBlurNombreTarjeta}
                        style={styleInput}
                    />
                {/* </Tooltip> */}
                {errorNombreTarjetaMsg.nombreTarjeta && <p style={{lineHeight:'12px'}} className='leyendaError'>{errorNombreTarjetaMsg.nombreTarjeta}</p>}
            </Form.Item>

            <Form.Item style={rowStyle} rules={[{ required: true, min: toValidInput.min, max: toValidInput.max, message: '${label} invalido' }]}>
                {/* <Tooltip placement="top" title={errorNumeroTarjetaMsg.numeroTarjeta}> */}
                    <Input
                        status={errorNumeroTarjeta}
                        name='numeroTarjeta'
                        size='large'
                        placeholder='Número de tarjeta'
                        ref={inputNumeroTarjeta}
                        min={toValidInput.min}
                        maxLength={toValidInput.max}
                        onChange={validateInput}
                        onFocus={handleOnFocus}
                        onBlur={handleBlurNumeroTarjeta}
                        value={formValues.numeroTarjeta}
                        disabled={isDisabled}
                        data-openpay-card="card_number"
                        style={styleInput}
                        onClick={handleClick}
                    />
                {/* </Tooltip> */}
                {errorNumeroTarjetaMsg.numeroTarjeta && <p style={{lineHeight:'12px'}}  className='leyendaError'>{errorNumeroTarjetaMsg.numeroTarjeta}</p>}
            </Form.Item>
            <Row style={rowStyle}>
                <Col style={{ paddingRight: 10 }} xs={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }} className="gutter-row" >
                    <Form.Item rules={[{ required: true, message: '${label} invalida' }]}>
                        {/* <Tooltip placement="top" title={errorFechaTarjetaMsg.fechaTarjeta}> */}
                            <Input
                                status={errorFechaTarjeta}
                                name='fechaTarjeta'
                                size='large'
                                placeholder='Fecha vencimiento'
                                ref={datepickertarjeta}
                                onChange={validateInput}
                                min={5}
                                maxLength={5}
                                value={formValues.fechaTarjeta}
                                disabled={isDisabled}
                                onFocus={handleOnFocus}
                                onBlur={handleBlurFechaTarjeta}
                                style={styleInput}
                            />
                        {/* </Tooltip> */}
                        {errorFechaTarjetaMsg.fechaTarjeta && <p style={{lineHeight:'12px'}} className='leyendaError'>{errorFechaTarjetaMsg.fechaTarjeta}</p>}
                    </Form.Item>
                </Col>
                <Col style={{ paddingLeft: 10 }} xs={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} md={{ span: 12, offset: 0 }} lg={{ span: 12, offset: 0 }} className="gutter-row" >
                    <Form.Item className='ccv' rules={[{ required: true, min: toValidCvv.min, max: toValidCvv.max, message: '${label} invalido' }]}>
                        {/* <Tooltip placement="top" title={errorCcvMsg.ccv}> */}
                            <Input
                                status={errorCcv}
                                name='ccv'
                                size='large'
                                placeholder='CVV'
                                ref={inputCcv}
                                onChange={validateInput}
                                min={toValidCvv.min}
                                maxLength={toValidCvv.max}
                                value={formValues.ccv}
                                disabled={isDisabled}
                                onFocus={handleOnFocus}
                                onBlur={handleBlurCcvTarjeta}
                                style={styleInput}
                            />
                        {/* </Tooltip> */}
                        {errorCcvMsg.ccv && <p className='leyendaError'>{errorCcvMsg.ccv}</p>}
                    </Form.Item>
                </Col>
            </Row>
            <ModalPayment setModalOpen={setModalOpen} modalOpen={modalOpen} textmodal={textmodal} titlemodal={titlemodal}></ModalPayment>
        </Form>
    )
}

