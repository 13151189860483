import React from 'react';
import { Modal, Layout, Typography, Row, Col, Button } from 'antd';

const { Header,Content } = Layout;
const { Title } = Typography;


/**
 * Componente para mostrar un error por medio de modal
 * @param {setModalOpen,modalOpen,textmodal,titlemodal} ModalPayment 
 * @returns vista con diseño de modal para errores
 */
function ModalPayment({setModalOpen, modalOpen, textmodal, titlemodal}) {
  
const headerStyle = {
  textAlign: 'center',
  height: 0,
  paddingInline: 50,
  lineHeight: '64px',
  backgroundColor: '#ffffff',
};
const contentStyle = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  backgroundColor: '#ffffff',
};


 const handleCancel = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Modal
        title=""
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        width={700}
         footer={[
          <Button key="submit" type="primary"  onClick={handleCancel}>
            Cerrar
          </Button>
        ]}
      >
        <Layout>
        <Header style={headerStyle}></Header>
        <Content style={contentStyle}>
          <Title level={2}>{titlemodal}</Title>
          <Row>
            <Col xs={{  span: 18, offset: 3 }} sm={{  span: 18, offset: 3 }} md={{  span: 18, offset: 3 }} lg={{ span: 18, offset: 3 }} className="gutter-row" >
              <Title level={4}>{textmodal}</Title>
            </Col>
          </Row>
        </Content>
      </Layout>
      </Modal>
    </>
  );

}

export default ModalPayment;