import React, { useEffect, useState } from 'react';
import { Grid, Typography, Row, Col, Spin, Result, ConfigProvider, Card } from 'antd';
import whatsappico from '../img/whatsapp-icon.png';
import llamadaico from '../img/llamadaicon.png';
import NavBar from './NavBar';
import { recuperarCargoOpenPay } from '../api/openPay';
import { getDataPedidoOrigen, getDataLigasIdPedido, getDataPedido, getDataLigas } from '../api/pagoPasarela';
import { desencryptURL } from '../utils/helper';
import { getDataTipoSucursal } from '../api/sucursalesService';
import { enviarCorreoPago } from '../api/enviarCorreoPago';
import { updateDataLiga } from '../api/pagoPasarela';
import { Footer } from '../components/Footer';
const { Title } = Typography;


/**
 * Componente para mostrar vista de liga pagada
 * @param {idCargo} Pagado 
 * @returns vista con diseño de pagado
 */
const Pagado = ({ idCargo }) => {

  const cardPrincipalStyle = {
    width: '100%',
    boxShadow: '1px 10px 10px 1px rgba(90, 116, 148, 0.3)',
    borderRadius: '0px',
    marginBottom: '30px',
    zIndex: ''
  };

  const cardBodyStyle = {
    width: '100%',
    borderRadius: '0px',
    height: '100%',
  };

  const rowFooterStyle = {
    bottom: '0px',
    position: 'absolute',
    width: '100%',
    marginTop: '0px'
  };

  const footerStyle = {
    textAlign: 'center',
    color: 'gray',
    position: 'absolute',
    width: '100%',
    marginTop: '30px',
    paddingLeft: '0px',
    paddingRight: '0px',
  };

  const containerStyle = {
    position: 'absolute',
    maxwidth: '1200px',
    maxHeight: '1456px',
    alingcontent: 'center'
  };

  const stylesNavBar = {
    navBarHeigth: '70px',
    iconsNavbarWidth: '200px',
    iconsNavbarHeigth: '200px',
    stepsWidth: '25px',
    stepsHeigth: '25px',
    stepsSize: '',
    stepsCurrent: 1
  };

  const divresponsive = {
    position: 'relative',
    display: 'grid',
    placeItems: 'center',
    aling: 'center',
    top: '0',
    bottom: '0',
    minHeight: '150px',
    height: '100vh'
  };

  const divStyle = {
    position: 'flex',
    background: 'linear-gradient(270deg, #1a3992 0%, #e6002b 100%)',
    height: '2px',
    transform: 'matrix(-1, 0, 0, 1, 0, 0)',
    bottom: '0px',
    marginBottom: '100px'
  }

  const [isSuccess, setIsSuccess] = useState({});
  const [loader, setLoader] = useState(true);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const data = [{
    id: 1
  }]

  async function responsiveSize(screens) {
    let screennow;
    {
      Object.entries(screens)
        .filter((screen) => !!screen[1])

        .map((screen) => (
          screennow = screen[0]
        ))
    }

    switch (screennow) {
      case "xxl":
        containerStyle.top = '2%';
        stylesNavBar.navBarHeigth = '65px';
        stylesNavBar.iconsNavbarWidth = '200px';
        stylesNavBar.iconsNavbarHeigth = '200px';
        stylesNavBar.stepsSize = 'large';
        stylesNavBar.paddingImg = '0px';
        cardPrincipalStyle.width = '98%';

        divresponsive.minHeight = '360px';
        rowFooterStyle.marginTop = '0px';
        rowFooterStyle.bottom = '0px';

        break;
      case "xl":

        containerStyle.top = '2%';
        stylesNavBar.navBarHeigth = '65px';
        stylesNavBar.iconsNavbarWidth = '200px';
        stylesNavBar.iconsNavbarHeigth = '200px';
        stylesNavBar.stepsSize = 'large';
        stylesNavBar.paddingImg = '0px';

        cardPrincipalStyle.width = '98%';

        rowFooterStyle.marginTop = '0px';
        rowFooterStyle.bottom = '0vh';
        divresponsive.minHeight = '360px';

        break;
      case "lg":

        containerStyle.top = '2%';
        stylesNavBar.navBarHeigth = '65px';
        stylesNavBar.iconsNavbarWidth = '200px';
        stylesNavBar.iconsNavbarHeigth = '200px';
        stylesNavBar.stepsSize = 'large';
        stylesNavBar.paddingImg = '10px';

        cardPrincipalStyle.width = '100%';

        rowFooterStyle.marginTop = '0px';
        rowFooterStyle.bottom = '0px';
        divresponsive.minHeight = '360px';

        break;
      case "md":

        containerStyle.top = '2%';
        stylesNavBar.navBarHeigth = '100px';
        stylesNavBar.iconsNavbarWidth = '150px';
        stylesNavBar.iconsNavbarHeigth = '150px';
        stylesNavBar.stepsSize = 'small';
        stylesNavBar.paddingImg = '10px';
        cardPrincipalStyle.width = '100%';

        rowFooterStyle.marginTop = '0px';
        rowFooterStyle.bottom = '0px';
        divresponsive.minHeight = '860px';

        break;
      case "sm":

        containerStyle.top = '2%';
        stylesNavBar.navBarHeigth = '100px';
        stylesNavBar.iconsNavbarWidth = '150px';
        stylesNavBar.iconsNavbarHeigth = '150px';
        stylesNavBar.stepsSize = 'small';
        stylesNavBar.paddingImg = '10px';

        cardPrincipalStyle.width = '100%';

        rowFooterStyle.marginTop = '0px';

        rowFooterStyle.bottom = '0px';
        divresponsive.minHeight = '860px';

        break;
      case "xs":

        stylesNavBar.navBarHeigth = '100px';
        stylesNavBar.iconsNavbarWidth = '150px';
        stylesNavBar.iconsNavbarHeigth = '150px';
        stylesNavBar.stepsSize = 'small';
        stylesNavBar.paddingImg = '10px';
        cardPrincipalStyle.width = '100%';


        rowFooterStyle.marginTop = '0px';
        rowFooterStyle.bottom = '0px';

        divresponsive.minHeight = '860px';

        break;

      default:
        break;
    }
  }
  responsiveSize(screens)

  useEffect(() => {
    debugger;
    async function recuperarCargo() {
      try {
        if (idCargo.length < 25) {
          const idCargoOpenpay = idCargo.split('=');
          debugger;
          let response = await recuperarCargoOpenPay(idCargoOpenpay[1]);
          setIsSuccess(response.data);
          if (response.data.status == "completed") {
            let pedidoId = response.data.orderId.split("-")[1];
            let pedidos = await getDataPedidoOrigen(pedidoId, 9);
            let pedido = pedidos.data.data.at(-1);
            let dataLiga = await getDataLigasIdPedido(pedido.id);
            dataLiga.data.data[0].fechaHoraPago = Date.now();
            dataLiga.data.data[0].nombreTarjeta = response.data.card.holderName;
            dataLiga.data.data[0].pagoExternoId = response.data.id;
            dataLiga.data.data[0].tarjeta = response.data.card.cardNumber;
            let responseDataPedido = await getDataPedido(dataLiga.data.data[0].pedidoPasarelaId);
            let sucursal = await getDataTipoSucursal(responseDataPedido.data.data.sucursalId);

            var date = new Date();
            var day = date.getDate();       // yields date
            var month = date.getMonth() + 1;    // yields month (add one as '.getMonth()' is zero indexed)
            var year = date.getFullYear();  // yields year
            var hour = String(date.getHours());     // yields hours 
            var minute = String(date.getMinutes()); // yields minutes
            var second = String(date.getSeconds()); // yields seconds
            var splithour = "";
            var splitminute = ""
            var splitsecond = ""
            if (hour.length < 2) {
              splithour = "0" + hour;
            } else {
              splithour = hour;
            }
            if (minute.length < 2) {
              splitminute = "0" + minute;
            }
            else {
              splitminute = minute;
            }
            if (second.length < 2) {
              splitsecond = "0" + second;
            } else {
              splitsecond = second;
            }


            // After this construct a string with the above results as below           
            var time = year + "-" + month + "-" + day + "T" + splithour + ':' + splitminute + ':' + splitsecond;
            var digitosTarjeta = response.data.card.cardNumber.substring(response.data.card.cardNumber.length - 4);
            var isDebit = true;

            if (response.data.card.type == "debit") {
              isDebit = true;
            } else {
              isDebit = false;
            }
            const newState = await dataLiga.data.data.map(obj => {
              return {
                ...obj,
                fechaHoraPago: time,
                nombreTarjeta: response.data.card.holderName,
                pagoExternoId: response.data.id,
                tarjeta: parseInt(digitosTarjeta),
                estatusId: 2,
                esDebito: isDebit,
                autorizacion: response.data.authorization,
                codigoBanco: response.data.card.bankCode,
                nombreBanco: response.data.card.bankName,
                numeroTarjeta: response.data.card.cardNumber
              };
            });
            var responseUpdated = await updateDataLiga(newState[0]);
            await enviarCorreoPago(response.data.card.holderName, response.data.orderId.split("-")[1], response.data.amount, dataLiga.data.data[0].correo, sucursal);
          }
          setLoader(false);
        } else {
          let responseCrypt = await desencryptURL(idCargo);
          if (responseCrypt != false) {
            let responseDataLiga = await getDataLigas(responseCrypt);
            if (responseDataLiga.data.data.estatusId == 2) {
              let obj = {
                status: "completed",
              }
              setIsSuccess(obj);
              setLoader(false);
            }
          }
        }
      } catch (error) {
        setLoader(false);
      }
    }
    recuperarCargo();
  }, []);

  return (

    <div className="Error">
      <NavBar stylesNavBar={stylesNavBar} sucursal={data} />
      <div style={divStyle}></div>
      <div style={divresponsive}>
        <div className="container" style={containerStyle}>

          {loader ? (<Spin size="large" />) : (
            // <Content style={contentStyle}>
            <Card style={cardPrincipalStyle} bodyStyle={cardBodyStyle} >
              {isSuccess.status == "completed" ?
                (<div>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorSuccess: '#53B927',
                        subtitleFontSize: 16
                      },
                      components: {
                        Result: {
                          extraMargin: '0px 0 0 0',
                          subtitleFontSize: 20,
                          colorTextHeading: '#53B927',
                          colorTextDescription: '#3D3D3D'
                        }
                      }
                    }}
                  >
                    <Result
                      status="success"
                      title="¡Pago realizado!"
                      subTitle="Tu pago ha sido realizado con éxito. Gracias por tu compra."

                    />
                  </ConfigProvider>
                </div>
                )
                :
                (
                  <div>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorSuccess: '#ff4d4f',
                        subtitleFontSize: 16
                      },
                      components: {
                        Result: {
                          extraMargin: '0px 0 0 0',
                          subtitleFontSize: 20,
                          colorTextHeading: '#ff4d4f',
                          colorTextDescription: '#3D3D3D'
                        }
                      }
                    }}
                  >
                    <Result
                      status="error"
                      title="¡Pago rechazado!"
                    />
                  </ConfigProvider>
                </div>
                )
              }
              <Row>
                <Col style={{ textAlign: 'center' }} xs={{ span: 19, offset: 3 }} sm={{ span: 19, offset: 3 }} md={{ span: 19, offset: 3 }} lg={{ span: 19, offset: 3 }} className="gutter-row" >
                  <Title level={4}>Para cualquier duda o seguimiento, contacta a  un ejecutivo de nuestro centro de atención.</Title>
                </Col>
              </Row>
              <Row style={{ display: 'fkex', alignItems: 'center' }}>
                <Col xs={{ span: 1, offset: 2 }} sm={{ span: 1, offset: 5 }} md={{ span: 1, offset: 6 }} lg={{ span: 1, offset: 8 }} >
                  <div style={{ display: 'flex' }}>
                    <img style={{ marginTop: -4 }} src={llamadaico} width={39} height={39}></img>
                    <p style={{ margin: 12, marginTop: 5, color: 'black' }}>3332084440</p>
                  </div>
                </Col>
                <Col xs={{ span: 1, offset: 10 }} sm={{ span: 1, offset: 7 }} md={{ span: 1, offset: 7 }} lg={{ span: 1, offset: 3 }} >
                  <a href="https://wa.me/3317517975?text=¡Necesito ayuda con mi pago!">
                    <div style={{ display: 'flex' }}>
                      <p style={{ margin: 12, marginTop: 5, color: 'black' }}>3317517975</p>
                      <img style={{ marginTop: -4 }} src={whatsappico} width={35} height={35}></img>
                    </div>
                  </a>
                </Col>
              </Row>
            </Card>
          )}
        </div>
        <div style={rowFooterStyle}>
          <Row >
            <Col xs={{ span: 24, order: 5 }} sm={{ span: 24, order: 5 }} md={{ span: 24, order: 5 }} lg={{ span: 24, order: 5 }} xl={{ span: 24, order: 5 }} xxl={{ span: 24, order: 5 }}>
              <Footer footerStyle={footerStyle}></Footer>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Pagado;