import { React, useRef, useState, useEffect } from 'react'
import { Row, Col, Card, Image } from 'antd';
import chip from '../img/chip-tarjeta.png';
const icons = require.context('../img/', true);

/**
 * Componente/Formulario para ingresar datos de tarjeta
 * @param {ReceiveData,activeToggle,cardType,stylesCard} DatosTarjeta 
 * @returns formulario con funciones de validaciónes y envío de datos
 */
export const DatosTarjeta = ({ ReceiveData, activeToggle, cardType, stylesCard }) => {
    const targeta = useRef();
    const delantero = useRef();
    const trasero = useRef();

    const styleCard = {
        backgroundImage: 'linear-gradient(to bottom right, #7d7c7a, #bcbcbc)',
        padding: '0px',
        width: stylesCard.width,
        maxWidth: '400px',
        height: '250px'
    };

    const gridStyle = {
        width: '70%',
        textAlign: 'left',
        height: '5%',
        fontSize: '16px',
        color: '#FFFFFF',
        boxShadow: '0px 0px 0px #ffffff'
    };

    const colStyle = {
        paddingTop: '4px'
    };

    const gridStyle2 = {
        textAlign: 'left',
        handleLineWidth: '0px',
        fontSize: '16px',
        color: '#FFFFFF',
        boxShadow: '0px 0px 0px #ffffff'

    };

    const gridStyle3 = {
        width: '30%',
        textAlign: 'top',
        height: '5%',
        color: '#FFFFFF',
        boxShadow: '0px 0px 0px #ffffff'
    };
    
    const gridStyleImg = {
        width: '100%',
        textAlign: 'left',
        handleLineWidth: '0px',
        boxShadow: '0px 0px 0px #ffffff',
        position:'absolute'
    };

    const gridStyleImg2 = {
        width: '100%',
        textAlign: 'right',
        handleLineWidth: '0px',
        height: '20%',
        boxShadow: '0px 0px 0px #ffffff'
    };

    const barraMagnetica = {
        height: '40px',
        background: '#000',
        width: '100%',
        position: 'absolute',
        top: '30px',
        left: '0'
    };

    const gridStyleNumeroTarjeta = {
        width: '100%',
        textAlign: 'left',
        handleLineWidth: '0px',
        fontSize: '20px',
        color: '#FFFFFF',
        paddingTop: '0px',
        boxShadow: '0px 0px 0px #ffffff'
    };

    const gridStyleNombreTarjeta = {
        width: '70%',
        textAlign: 'left',
        height: '5%',
        fontSize: '16px',
        color: '#FFFFFF',
        paddingTop: '0px',
        boxShadow: '0px 0px 0px #ffffff',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    };

    const gridStyleFechaTarjeta = {
        width: '30%',
        textAlign: 'top',
        height: '5%',
        color: '#FFFFFF',
        paddingTop: '4px',
        boxShadow: '0px 0px 0px #ffffff'
    };

    const styleTarjeta = {
        maxWidth: '400px',
        paddingLeft: '10px'
    };

    const [bancoImgTarjeta, setBancoImgTarjeta] = useState('50px');

    const HandleOnClick = (e) => {
        if (!e.currentTarget.parentNode.classList.contains('active')) {
            targeta.current.classList.add('active');
        } else {
            targeta.current.classList.remove('active');
        }
    }

    useEffect(() => {
        if (cardType == 'Visa') {
            setBancoImgTarjeta('76px');
        }else{
            setBancoImgTarjeta('50px');
        }
    });
    
    return (
        <div ref={targeta} style={styleTarjeta} className={activeToggle ? 'tarjeta active' : 'tarjeta'} >
            <Card style={styleCard} className='cara delantera' id='delantero' ref={delantero} onClick={HandleOnClick}>
                <Card.Grid hoverable={false} style={gridStyleImg2} className='delantero'>
                    <Image
                        preview={false}
                        width={bancoImgTarjeta}
                        src={icons(`./${cardType}.png`)}
                    />
                    <Col hoverable={false} style={gridStyleImg} className='delantero'>
                        <Image
                            style={{ marginTop: -10 }}
                            className='chip'
                            preview={false}
                            width={160}
                            height={30}
                            src={chip}
                        />
                    </Col>
                </Card.Grid>

                <Card.Grid hoverable={false} style={gridStyleNumeroTarjeta} className='delantero'>
                    {ReceiveData.numeroTarjeta !== '' ? ReceiveData.numeroTarjeta : 'XXXX  XXXX  XXXX  XXXX'}
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyleNombreTarjeta} className='delantero'>
                    {ReceiveData.nombreTarjeta !== '' ? ReceiveData.nombreTarjeta : 'Nombre del titular'}
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyleFechaTarjeta} className='delantero'>
                    {ReceiveData.fechaTarjeta !== '' ? ReceiveData.fechaTarjeta : 'MM/AA'}
                </Card.Grid>
            </Card>

            <Card style={styleCard} className='cara trasera' id='trasero' ref={trasero} onClick={HandleOnClick}>
                <Card.Grid hoverable={false} style={gridStyle2}>
                    <div style={barraMagnetica}></div>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle}>
                    <Row id='firma'>
                        <Col span={24} style={colStyle}>
                            <p className="label">Firma</p>
                            <div className="firma"><p>{ReceiveData.nombreTarjeta !== '' ? ReceiveData.nombreTarjeta : ''}</p></div>
                        </Col>
                    </Row>
                </Card.Grid>
                <Card.Grid hoverable={false} style={gridStyle3}>
                    <Row id='cvv'>
                        <Col span={24}>
                            <p className="label">CVV</p>
                            <div className="cvv">{ReceiveData.ccv}</div>
                        </Col>
                    </Row>
                </Card.Grid>
            </Card>
        </div>
    )
}
