import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Error from './components/Error';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider} from 'react-router-dom'
import { ConfigProvider } from 'antd';
import es_ES from 'antd/locale/es_ES';
window.Buffer = require('buffer/').Buffer;

const router = createBrowserRouter([
  {
    path:"*",
    element:<App />,
    errorElement:<Error/>
  },
  {
    path:"/Error",
    element:<Error />,
    errorElement:<Error/>
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ConfigProvider locale={es_ES}>
    <React.StrictMode>
      <RouterProvider router={router}/>
    </React.StrictMode>
  </ConfigProvider>
);

reportWebVitals();
