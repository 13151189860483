import { React } from 'react'
import { Typography, Row, Layout } from 'antd';

const { Content } = Layout;
const { Title } = Typography;
const titleStyle = {
    textAlign: 'center',
    color: 'red',
};
const subtitleStyle = {
    color: 'red',
};

const contentStyle = {
    textAlign: 'left',
    backgroundColor: '#ffffff',
    textAlign: 'Justify'
};

function TerminosCondiciones() {

    return (
        <Content style={contentStyle}>
           <Title level={2} style={titleStyle} >Terminos y Condiciones</Title>
              <Title level={4} style={subtitleStyle}>
                Política de envío
              </Title>
              <Title level={5}>
                El servicio de envíos de www.apymsa.com.mx está respaldado por la colaboración con las mejores empresas de mensajería y envío en el territorio nacional, cada una de las compras realizadas están respaldadas por las políticas de envío de las empresas involucradas.
                <br></br><br></br>
                El cumplimiento oportuno de la entrega de las compras hechas en www.apymsa.com.mx dependerá de que los clientes hayan proporcionado la información de envío de manera suficiente y veraz, habiendo llenado cada uno de los apartados solicitados para realizar la entrega
                <br></br><br></br>
                El periodo de envío aproximado es de 24 a 72 horas a partir de la liberación del cobro, es decir, cuando el sistema registre la compra, sólo entonces iniciará el proceso de envío y estará sujeto a disponibilidad, días hábiles y horarios laborales estándar. A su vez, el tiempo de entrega de la compra estará sujeta al código postal del usuario y las zonas extendidas. En cualquier caso, todas las transacciones en www.apymsa.com.mx se harán a través de la plataforma Openpay.
                <br></br><br></br>
                Una vez iniciado el proceso de entrega, desde que se embarca hasta que está en manos de los clientes, las compras estarán sujetas a las políticas de entrega de cada una de las compañías de mensajería involucradas, cada una de ellas hará lo necesario para hacer la entrega, si no encuentra habitantes en el domicilio o no encuentra el mismo domicilio, actuará en correspondencia a su procedimiento estándar, ya sea comunicándose por número telefónico o haciendo hasta 3 intentos para completar la entrega.
                <br></br><br></br>
                En caso de accidente o extravío por parte del servicio de mensajería, se abrirá un caso, se reportará con la empresa en cuestión y www.apymsa.com.mx hará el reembolso correspondiente.
                <br></br><br></br>
                Es responsabilidad del cliente comprobar el buen estado del paquete ante el transportista y en dado caso, si se encuentra alguna anomalía, reportarlo al momento y rechazarlo, si la afectación o el daño del paquete es considerable y pone en riesgo la calidad del producto. Al mismo tiempo, deberá hacerse un reporte a www.apymsa.com.mx en el área correspondiente, si no es levantado un reporte al momento de la entrega, la garantía no podrá ser cubierta.
                <br></br><br></br>
                La instalación de los productos es completa responsabilidad del cliente. Ni el personal de mensajería, ni de www.apymsa.com.mx está autorizado para instalar, intervenir o manipular ninguno de los productos. Cualquier intervención no autorizada invalida la garantía.
                <br></br><br></br>
                El proceso de envío y entrega es posible gracias a la colaboración de diferentes servicios y trabajamos en conjunto para darte el mejor servicio en cada una las compras, sin embargo, los incidentes del mundo real son inevitables y somos conscientes de ello, agradecemos tu comprensión ante alguno de ellos y ten la seguridad que será atendido y respondido. Si tienes alguna dificultad, no dudes en comunicarte con nosotros para resolverla.
              </Title>
        </Content>
    )
}

export default TerminosCondiciones;