import React, { useState, useEffect } from 'react';
import { List, Skeleton, ConfigProvider, Image, Space, } from 'antd';
import '../styles/listaproductos.css'

/**
 * Componente para mostrar el listado de los productos del pedido
 * @param {productos} ListaProductos 
 * @returns vista con diseño de lista de productos
 */
export const ListaProductos = ({ productos }) => {
  const [initLoading, setInitLoading] = useState(true);
  const [list, setList] = useState([]);
  useEffect(() => {
        setList(productos);
        setInitLoading(false);
  }, []);

  const precioStyle ={
    padding:'8px'
  }

  const IconText = ({ descripcion, text }) => (
    <div>
      <Space>
        {descripcion}
      </Space>
      <br>
      </br>
      <Space>
        {text + "pz"}
      </Space>
    </div>
  );

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorTextDescription: 'black',
            borderRadiusLG: 0,
            padding: 5,
            paddingContentHorizontal: 30,
            paddingContentHorizontalLG: 33,
          },
          components: {
            List: {
              descriptionFontSize: 12,
            },
            Avatar: {
              containerSize: 40
            }
          }
        }}
      >
        <List
          className="demo-loadmore-list"
          loading={initLoading}
          itemLayout="horizontal"
          dataSource={list}
          renderItem={(item) => (
            <List.Item>
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  avatar={
                    <Image
                      width={50}
                      src={item.imagen}
                    />
                  }
                  title={<a href="https://ant.design">{item.name?.last}</a>}
                  description={<IconText descripcion={item.descripcion} text={item.cantidad} key="list-vertical-star-o" />}
                />
                <div style={precioStyle}>${item.precioConIva}</div>
              </Skeleton>
            </List.Item>
          )}
        />
      </ConfigProvider>
    </>
  );
};
