import './App.css';
import { useState, useEffect } from 'react';
import PasarelaPago from './Layout/PasarelaPago';
import Error from './components/Error';
import Pagado from './components/Pagado';
import { desencryptURL, createProductosObj } from './utils/helper';
import { getDataLigas, getDataPedido, getDataDetallePedido } from './api/pagoPasarela';
import { getProductosDescripciones } from './api/productoService';
import { validLiga } from './utils/helper';
import { loadOpenPay, loadAntiFraud } from './api/openPay';
import { getDataTipoSucursal } from './api/sucursalesService';
import { Token } from './api/token';
//import { credentials } from './credentials/credentialsToken';

/**
 * Metodo principal al ingresar a la app, valida información del parametro enviado por url
 * @returns Arreglo de objetos con información necesaria para ingresar al formulario
 */
async function mainFunction() {
  const valores = window.location.search;
  debugger;
  if (valores.length > 0) {
    if (valores.indexOf("?id=") === 0) {
      return "pagado";
    }
    let responseCrypt = await desencryptURL(valores);
    console.log(responseCrypt);
    debugger;
    if (responseCrypt != false) {
      let dataReturned = [];
      let responseDataLiga = await getDataLigas(responseCrypt);
      if (responseDataLiga.data.data.estatusId == 2) {
        return "pagado";
      } else {
        let ligavalidada = await validLiga(responseDataLiga.data.data);
        if (ligavalidada == true) {
          let responseDataPedido = await getDataPedido(responseDataLiga.data.data.pedidoPasarelaId);
          let sucursal = await getDataTipoSucursal(responseDataPedido.data.data.sucursalId);
          dataReturned.push(responseDataLiga.data.data);
          dataReturned.push(responseDataPedido.data.data);
          let responseDataPedidoDetalle = await getDataDetallePedido(responseDataLiga.data.data.pedidoPasarelaId);
          let dataPedidoDetalle = [];
          dataPedidoDetalle = responseDataPedidoDetalle.data.data;
          let productosId = [];
          dataPedidoDetalle.forEach(element => {
            productosId.push(element.productoId);
          });
          let detalleproducto = await getProductosDescripciones(productosId);
          let objResumenProducto = await createProductosObj(dataPedidoDetalle, detalleproducto);
          dataReturned.push(objResumenProducto);
          dataReturned.push(sucursal.data.data);
          return dataReturned;
        } else {
          return false;
        }
      }
    } else {
      return false
    }
  } else {
    return false
  }
}

/**
 * Metodo para obtener un Token del servicio,
 * @returns token en sessionStorage para uso en las demás peticiónes
 */
async function token() {
  var myToken = sessionStorage.getItem("tokenBearer");
  if (myToken == null) {
    var responseToken = await Token();
    if (responseToken.data.succeeded) {
      sessionStorage.setItem("tokenBearer", responseToken.data.accessToken);
    } else {
      sessionStorage.setItem("tokenBearer", false);
    }
  }
}

/**
 * Funcion que obtiene el arreglo de objetos del metodo principal mediante un effect
 * @returns Componente principal PasarelaPago o effect que muestra componente de Error o Pagado 
 */
function App() {
  let [datalog, dataLog] = useState();
  let [error, errorP] = useState();

  //Recuperar el id del cargo de la url para validar estatus del pago = Completado
  const idCargoOpenpay = window.location.search;

  useEffect(() => {
    const dataLogResponse = async () => {
      loadOpenPay(false);
      loadAntiFraud(false);
      await token();
      debugger;

      //checar si es el token de sesion.
      if (sessionStorage.getItem("tokenBearer") != null) {
        const response = await mainFunction();
        if (response == "pagado") {
          errorP(<Pagado idCargo={idCargoOpenpay} />)
        } else {
          if (response != false) {
            dataLog(response);
          } else {
            errorP(<Error />)
          }
        }
      } else {
        errorP(<Error />)
      }
    }
    dataLogResponse();
  }, []);

  return (
    <div>
      {datalog ? <PasarelaPago data={datalog} /> : error}
    </div>
  );
}

export default App;