import NodeRSA from 'node-rsa';
import { actualizaLiga } from '../api/pagoPasarela';

/**
 * 
 * @param {*} src Ubicación de archivo OpenPayJS
 * @param {*} position Valida si esta set el script
 * @param {*} id idMerchant
 * @returns null
 */
export default function loadScript(src, position, id) {
  if (!position) {
    return;
  }
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

/**
 * 
 * @param {*} innerHtml Establecer js en dom
 * @param {*} position saber si ya esta set el script
 * @param {*} id idMerchant
 * @returns Null
 */
export function loadAndSetScript(innerHtml, position, id) {
  if (!position) {
    return;
  }
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.type = 'text/javascript';
  script.innerHTML = innerHtml;
  position.appendChild(script);
}

/**
 * Metodo para desecriptar parametro en url
 * @param {*} token parametro vía url para obtener información de la liga
 * @returns valor desencriptado
 */
export const desencryptURL = async (token) => {
  try {
    let rsakey = new NodeRSA(process.env.REACT_APP_PRIVATE_KEY.replace(/\\n/g, '\n'), 'pkcs1-private-pem', {
      'encryptionScheme': 'pkcs1'
    });
    let decrypt = rsakey.decrypt(token);
    let decryptString = decrypt.toString('utf8');
    console.log(decryptString);
    debugger;
    return decryptString;
  } catch (e) {
    return false;
  }
}


/**
 * Metodo para validación de 2 horas disponibles para visualización de liga
 * @param {*} dateCompared fecha de tabla liga
 * @returns true o false en caso de que la liga se encuentre o no en el rango de menos de dos horas campo fecha de registro de liga
 */
export const validLiga = async (dateCompared) => {
  try {
    var currentDateObj = new Date();
    var numberOfMlSeconds = currentDateObj.getTime();
    var subMlSeconds = 120 * 60000;
    var newDateObj = new Date(numberOfMlSeconds - subMlSeconds);
    let dateResponseLiga = new Date(dateCompared.fechaHoraRegistro);
    if (dateResponseLiga > newDateObj && dateCompared.estatusId == 1) {
      return true;
    } else {
      if (await actualizaLiga(dateCompared.id, 4)) {
        return false;
      }
    }
  } catch (e) {
    return false;
  }
}

/**
 * 
 * @param {*} dataPedidoDetalle 
 * @param {*} detalleproducto 
 * @returns 
 */
export const createProductosObj = async (dataPedidoDetalle, detalleproducto) => {
  let obj=[];
  for (let index = 0; index < dataPedidoDetalle.length; index++) {
      for (let index2 = 0; index2 < detalleproducto.data.data.length; index2++) {
        if(dataPedidoDetalle[index].productoId==detalleproducto.data.data[index2].productoId){
          obj.push({
            productoId: dataPedidoDetalle[index].productoId,
            cantidad: dataPedidoDetalle[index].cantidad,
            precioConIva: dataPedidoDetalle[index].precioConIva,
            descripcion: detalleproducto.data.data[index2].descripcion,
            imagen: detalleproducto.data.data[index2].imagen
          })
        }
      }
  }
  return obj;
}

/**
 * 
 * @param {*s} errorOpenpay 
 * @returns 
 */
export const validOpenpayError = async (errorOpenpay) => {
  var error = "";
  if(errorOpenpay.includes(false)){
  }
  else if (errorOpenpay.includes("Internal server error, contact support")
    || errorOpenpay.includes("Bad Request")
    || errorOpenpay.includes("The api key or merchant id are invalid")
    || errorOpenpay.includes("Parameters look valid but request failed")
    || errorOpenpay.includes("The resource is unavailable at this moment. Please try again later")
    || errorOpenpay.includes("The requested resource doesn't exist")
    || errorOpenpay.includes("The order_id has already been processed")
    || errorOpenpay.includes("The request is too large")
    || errorOpenpay.includes("Method not allowed for public API key, use private key instead")
    || errorOpenpay.includes("The resource was previously deleted")
    || errorOpenpay.includes("Could not get any response from gateway. Please try again later")
    || errorOpenpay.includes("The merchant email has been already processed")
    || errorOpenpay.includes("The payment gateway is not available at the moment, please try again later")
    || errorOpenpay.includes("The number of retries of charge is greater than allowed")
    || errorOpenpay.includes("The number of decimal digits is not valid for this currency")
    || errorOpenpay.includes("The transactions included in your package have been completed. To contract another package contact support@openpay.mx")
    || errorOpenpay.includes("The bank account already exists")
    || errorOpenpay.includes("The external_id already exists")
    || errorOpenpay.includes("Merchant not authorized to use payment plan")
    || errorOpenpay.includes("Invalid promotion for such card type")
    || errorOpenpay.includes("Promotion not allowed")
    || errorOpenpay.includes("There are not enough funds in the openpay account")
    || errorOpenpay.includes("The operation can't be completed until pending fees are paid")
    || errorOpenpay.includes("The webhook has already been processed")
    || errorOpenpay.includes("Could not connect with webhook service, verify URL")
    || errorOpenpay.includes("Service responded with an error on this moment. Please try again later")
  ) {
    error = 'Estamos experimentando problemas con este método de pago, lamentamos las molestias que esto le ocasiona. ' +
      'Inténtalo de nuevo más tarde o cambia el método de pago.';
  } else if (errorOpenpay.includes("Operation rejected by processor")
    || errorOpenpay.includes("The account is inactive")
    || errorOpenpay.includes("The transaction amount exceeds your allowed transaction limit'")
    || errorOpenpay.includes("The operation is not allowed on the resource")
    || errorOpenpay.includes("Your account is inactive, please contact to soporte@openpay.mx for more information")
    || errorOpenpay.includes("The transaction amount exceeds your allowed transaction limit by TPV")
    || errorOpenpay.includes("The CoDi transactions contracted in your plan have been blocked")
  ) {
    error = 'Nuestro gestor de pago reporta un conflicto con la transacción de tu banco, puedes revisarlo directamente ' +
      'con tu banco o bien intentar con otro método de pago. Lamentamos las molestias.';
  } else if (errorOpenpay.includes("The card number is only valid in sandbox")
    || errorOpenpay.includes("The card is not valid for points")
    || errorOpenpay.includes("The CVV2 security code is invalid")
    || errorOpenpay.includes("3D Secure authentication failed")
    || errorOpenpay.includes("Card product type not supported")
    || errorOpenpay.includes("The card was declined by the bank")
    || errorOpenpay.includes("The card has expired")
    || errorOpenpay.includes("The card doesn't have sufficient funds")
    || errorOpenpay.includes("The card was reported as stolen")
    || errorOpenpay.includes("Fraud risk detected by anti-fraud system --- Found in blacklist")
    || errorOpenpay.includes("Request not allowed")
    || errorOpenpay.includes("The card was reported as lost")
    || errorOpenpay.includes("The bank has restricted the card")
    || errorOpenpay.includes("The bank has requested the card to be retained")
    || errorOpenpay.includes("Bank authorization is required for this charge")
    || errorOpenpay.includes("Fraud risk detected by anti-fraud system")
  ) {
    error = 'Por alguna razón, tu método de pago ha sido rechazado. Inténtalo de nuevo más tarde ' +
      ' o cambia el método de pago. Lamentamos las molestias que esto ocasiona.';
  
  } else {
    error = 'Estamos experimentando problemas con este método de pago, lamentamos las molestias que esto le ocasiona. ' +
      'Inténtalo de nuevo más tarde o cambia el método de pago.';
  }
  return error;
}

/**
 * 
 * @param {*} inputString 
 * @param {*} maxLength 
 * @returns 
 */
export const truncateString = async (inputString, maxLength) => {
  if (inputString.length <= maxLength) {
    return inputString;
  } else {
    return inputString.slice(0, maxLength) + '...';
  }
}
