import axios from 'axios';

/**
 * Componente para envío de correo de liga pagada.
 * @param {cliente,pedido,monto,correo,tipoSucursal} enviarCorreoPago 
 * @returns boolean
 */
export const enviarCorreoPago = async (cliente, pedido, monto, correo, tipoSucursal) => {
    let logoSucursal = 'LogoApymsa.png';
    if (tipoSucursal.data.data.id == 1) {
        logoSucursal = 'LogoApymsa.png';
    } else {
        logoSucursal = 'Logo_Autex_blanco.png';
    }
    var bodyCorreo = "<div style='width:800px;margin:auto;border:1px solid gray;font-family:Arial,Helvetica,sans-serif;'>" +
        "<div style='width:100%;height:180px;display:flex;align-items:center;background-color:#003396;text-align:center;'>" +
        "<img src='https://resources.apymsa.com.mx/imagenes/" + logoSucursal + "' style='width:250px;margin: 0 auto;'/>" +
        "</div>" +
        "<div style='padding:30px;font-size:18px;'>" +
        "<div>" +
        "<p><b>Estimado {CLIENTE}</b></p>" +
        "<p>Gracias por tu pago en línea de tu pedido solicitado por medio de nuestro Contact Center.</p>" +
        "<br>" +
        "<p style='text-align:center;'>Pedido <b>{PEDIDO}</b> por <b>$</b><b>{MONTO}</b> pesos</p>" +
        "</div>" +
        "<br>" +
        "<div style='font-size:17px;'>" +
        "<p><i>Si no realizaste este pedido te sugerimos no compartir tu cuenta, este correo solo es informativo, no es necesario respoder a el.</i></p>" +
        "</div>" +
        "</div>" +
        "</div>";



    bodyCorreo = bodyCorreo.replace('{CLIENTE}', cliente);
    bodyCorreo = bodyCorreo.replace('{PEDIDO}', pedido);
    bodyCorreo = bodyCorreo.replace('{MONTO}', monto);

    var data = {
        exchange: '',
        message: 'Confirmación de pago®' + bodyCorreo + '®' + correo,
        routingKey: 'channelCorreo'
    }

    const config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }
    }

    return await axios.post(process.env.REACT_APP_ENVIAR_CORREO, JSON.stringify(data), config)
        .catch(function (error) {
            if (error.response) {
                return false;
            }
        });
}