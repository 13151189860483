import { Row, Col, Image, } from 'antd';
import { NavBarSteps } from '../components/NavBarSteps';
import apymsaLogo from '../img/APYMSA 2020-.png';
import autexLogo from '../img/AUTEX-09.png';

/**
 * Componente para mostrar el navbar y steps de pago
 * @param {params} Navbar 
 * @returns vista con diseño de navbar
 */
const NavBar = (params) => {
    const navbgColor = {
        backgroundColor: '#ffffff',
        height: params.stylesNavBar.navBarHeigth,

    };
    const imgStyles = {
        width: params.stylesNavBar.iconsNavbarWidth,
        paddingBottom: params.stylesNavBar.paddingImg,
    };

    return (
        <Row justify="space-around" aling={"middle"} >
            <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 24, order: 1 }} lg={{ span: 24, order: 1 }} xl={{ span: 24, order: 1 }} xxl={{ span: 24, order: 1 }} style={navbgColor}>
                <br></br>
                <Row >
                    {params.sucursal.data ? (<>
                        <Col xs={{ span: 5, offset: 8 }} sm={{ span: 4, offset: 10 }} md={{ span: 5, offset: 11 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} xxl={{ span: 4, offset: 1 }} >
                            <Image
                                preview={false}
                                src={params.sucursal.data.at(-1).id == 1 ? apymsaLogo : autexLogo}
                                style={imgStyles}
                            />
                        </Col>
                        {params.stylesNavBar.stepsCurrent == 0 || params.stylesNavBar.stepsCurrent == 1 ? (<>
                            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 13, offset: 6 }} md={{ span: 10, offset: 8 }} lg={{ span: 10, offset: 3 }} xl={{ span: 8, offset: 2 }} xxl={{ span: 8, offset: 3 }} >
                                <NavBarSteps
                                    current={params.stylesNavBar.stepsCurrent}
                                    stepsSize={params.stylesNavBar.stepsSize}
                                />
                            </Col>
                        </>) :
                            (<>

                            </>)}
                    </>) :
                        (<>
                            <Col xs={{ span: 5, offset: 8 }} sm={{ span: 4, offset: 10 }} md={{ span: 5, offset: 11 }} lg={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} xxl={{ span: 4, offset: 1 }} >
                                <Image
                                    preview={false}
                                    src={apymsaLogo}
                                    style={imgStyles}
                                />
                            </Col>

                            <Col xs={{ span: 22, offset: 1 }} sm={{ span: 13, offset: 6 }} md={{ span: 10, offset: 8 }} lg={{ span: 10, offset: 3 }} xl={{ span: 8, offset: 2 }} xxl={{ span: 8, offset: 3 }} >
                                <NavBarSteps
                                    current={params.stylesNavBar.stepsCurrent}
                                    stepsSize={params.stylesNavBar.stepsSize}
                                />
                            </Col>
                        </>)}
                </Row>
                <br></br>
            </Col>
        </Row>
    )
}

export default NavBar;