import axios from 'axios';

const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem("tokenBearer")}` }
};

/**
 * Metodo para obtener información acerca de una sucursal
 * @param {sucursalId} getDataTipoSucursal 
 * @returns object con data de sucursal
 */
export const getDataTipoSucursal = async (sucursalId) => {
    return await axios.get(process.env.REACT_APP_SUCURSALESSERVICE+""+sucursalId.toString()+"/Negocio", config)
    .catch(function (error) {
        if (error.response) {
            return false;
        }
    });
}