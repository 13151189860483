import axios from 'axios';

/**
 * Metodo para obtener un token de logeo de app hacia mvc
 * @param {} Token 
 * @returns object con token
 */
export const Token = async () => {
    const credentials = {
        Email : process.env.REACT_APP_TOKEN_EMAIL,
        Contrasena : process.env.REACT_APP_TOKEN_PWD
    }
    return await axios.post(process.env.REACT_APP_TOKEN, credentials)
    .catch(function (error) {
        if (error.response) {
            return false;
        }
    });
}