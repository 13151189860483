import { React } from 'react'
import { Typography, Row, Layout } from 'antd';

const { Content } = Layout;
const { Title } = Typography;
const titleStyle = {
    textAlign: 'center',
    color: 'red',
};
const subtitleStyle = {
    color: 'red',
};

const contentStyle = {
    textAlign: 'left',
    backgroundColor: '#ffffff',
    textAlign: 'Justify'
};

function AvisoPrivacidad() {

    return (
        <Content style={contentStyle}>
            <Title level={2} style={titleStyle} >Aviso de Privacidad</Title>
            <Title level={5}>
                Última modificación: 29/03/22
                <br></br><br></br>
                En estricto cumplimiento a lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, mediante el presente aviso de privacidad hace de su conocimiento lo siguiente:
                <br></br>
                <Title level={4} style={subtitleStyle}>
                    IDENTIDAD DEL RESPONSABLE
                </Title>
                apymsa.com.mx, con domicilio social en Av. Juan Gil Preciado No. 4051-A, Colonia Hogares de Nuevo México, Código Postal 45138, en la Ciudad de Zapopan, Jalisco; recaba datos personales compartidos de manera voluntaria por el usuario. Dichos datos personales serán utilizados exclusivamente para fines comerciales.
                <br></br><br></br>
                <Title level={4} style={subtitleStyle}>
                    DATOS PERSONALES
                </Title>
                apymsa.com.mx hace de su conocimiento que los datos que el usuario implícitamente autoriza para recopilación y uso son: nombre de usuario, contraseña, nombre completo de la persona física o moral, correo electrónico, teléfono, domicilio, RFC, tipo de tarjeta, número de tarjeta, número CVC, fecha de vencimiento de la tarjeta, imagen parcial de la tarjeta empleada y en casos extraordinarios la imagen de la identificación para validar la identidad del comprador, dirección IP y cookies.
                <br></br><br></br>
                <Title level={4} style={subtitleStyle}>
                    FINALIDAD DEL TRATAMIENTO DE DATOS
                </Title>
                La información recabada por apymsa.com.mx tiene fines comerciales y de administración dentro del sistema únicamente; es decir, crear perfiles de cada uno de nuestros clientes, administrar sus compras, dar seguimiento a sus envíos y hacer entrega de los productos, razón por la cual se realizará la transferencia de información correspondiente a las empresas de paquetería con la finalidad de brindar un mejor servicio y hacer entregas a domicilio, así como se comparte la información fiscal para validar la facturación correspondiente ante el SAT.
                <br></br><br></br>
                <Title level={4} style={subtitleStyle}>
                    DERECHOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS PERSONALES
                </Title>
                apymsa.com.mx emplea procedimientos tecnológicos, físicos y administrativos apropiados para proteger la información proporcionada por el usuario con la finalidad de impedir el acceso no autorizado de los datos antes mencionados; sin embargo, es obligación de apymsa.com.mx señalar que ninguna transacción por internet es cien por ciento segura y libre de errores, por lo tanto, en apymsa.com.mx un servidor encripta los datos para resguardarlos de forma segura, según el protocolo Secure Socket Layer (SSL) para así proteger la información del usuario.
                <br></br><br></br>
                <Title level={4} style={subtitleStyle}>
                    TRANSFERENCIA DE DATOS
                </Title>
                En caso de que los datos resguardados por apymsa.com.mx sean requeridos por una autoridad de cualquier índole, ya sea por proceso legal, para responder a cualquier reclamo o acciones legales con motivo del cumplimiento de la relación jurídica celebrada entre el usuario y apymsa.com.mx, o para proteger los derechos de apymsa.com.mx o sus clientes y el público; estos datos se pondrán a disposición de la autoridad dentro del estricto cumplimiento a la ley. Esta transferencia se efectuará tomando las medidas razonables, necesarias y al alcance de apymsa.com.mx para proteger los datos personales contra daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado.
                <br></br><br></br>
                <Title level={4} style={subtitleStyle}>
                    MEDIOS PARA EJERCER LOS DERECHOS ARCO
                </Title>
                apymsa.com.mx, se abstendrá de vender, arrendar o alquilar sus datos personales y patrimoniales y los de sus empleados a un tercero. Respecto de sus datos personales, usted podrá ejercer los derechos de acceso, rectificación, cancelación y/u oposición (ARCO), mediante el envío de un correo electrónico a contacto@apymsa.com.mx, considerando que para estar en posibilidad de procesar cualesquiera de estas peticiones será indispensable incluir todos y cada uno de los requisitos estipulados en el numeral 29 de la Ley de Protección de Datos Personales en Posesión de los Particulares. Se considerará que contamos con su consentimiento tácito para el tratamiento de los datos acorde a los fines ya mencionados, mientras sean voluntarios y no se manifieste en oposición a su uso. Los datos recabados serán destruidos contados a partir de un año posterior a la ejecución del acto de comercio o terminación de la relación comercial (según corresponda) con la empresa apymsa.com.mx, a efecto de evitar un tratamiento indebido de los mismos, con fundamento en el artículo 11, segundo párrafo de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
                <br></br><br></br>
                <Title level={4} style={subtitleStyle}>
                    CONSENTIMIENTO
                </Title>
                De conformidad con lo que establece el artículo 9 de la Ley de Protección de Datos Personales, requerimos de su consentimiento expreso para el tratamiento de sus datos personales, con el fin de que, una vez que se entere de su contenido, otorgue o niegue su consentimiento para que se pueda llevar a cabo el tratamiento de sus datos personales por lo que le solicitamos indique si acepta o no el tratamiento.
                <br></br><br></br>
                <Title level={4} style={subtitleStyle}>
                    CAMBIOS AL AVISO DE PRIVACIDAD
                </Title>
                apymsa.com.mx se reserva el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios.
                <br></br><br></br>
                <Title level={4} style={subtitleStyle}>
                    NEGATIVA O REVOCACIÓN DE CONSENTIMIENTO
                </Title>
                El usuario tiene un plazo de cinco días hábiles para manifestar la negativa para el tratamiento de los datos personales planteados en el presente aviso de privacidad. De la misma forma, el usuario puede revocar el consentimiento a apymsa.com.mx en cualquier momento a través de la dirección de correo contacto@apymsa.com.mx
                <br></br><br></br>
                <Title level={4} style={subtitleStyle}>
                    AUTORIDAD COMPETENTE
                </Title>
                La autoridad competente para resolver cualquier conflicto derivado de la ley es el Instituto Nacional de Acceso a la Información y Datos Personales (INAI), mismo al que apymsa.com.mx ciñe en todo el tratamiento de datos personales y al que el usuario puede conocer en www.inai.org.mx
            </Title>
        </Content>
    )
}

export default AvisoPrivacidad;