import axios from 'axios';

const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem("tokenBearer")}` }
};

/**
 * Metodo para obtener una liga por id
 * @param {idLiga} getDataLigas 
 * @returns object con data de ligas
 */
export const getDataLigas = async (idLigas) => {
    return await axios.get(process.env.REACT_APP_LIGASPAGO +"/"+idLigas,config)
    .catch(function (error) {
        if (error.response) {
            return false;
        }
    });
}

/**
 * Metodo para obtener información de la liga relacionada a un pedido
 * @param {idPedido} getDataLigas 
 * @returns object con data de ligas por pedido
 */
export const getDataLigasIdPedido = async (idPedido) => {
    return await axios.get(process.env.REACT_APP_LIGASPAGOUPDATE +"="+idPedido,config)
    .catch(function (error) {
        if (error.response) {
            return false;
        }
    });
}

/**
 * Metodo para obtener información acerca de un pedido
 * @param {idPedido} getDataPedido 
 * @returns object con data de pedido
 */
export const getDataPedido = async (idPedido) => {
    return await axios.get(process.env.REACT_APP_PASARELAPEDIDO + idPedido,config)
    .catch(function (error) {
        if (error.response) {
            return false;
        }
    });
}

/**
 * Metodo para obtener información acerca del detalle un pedido
 * @param {idPedido} getDataDetallePedido 
 * @returns object con data del detalle de un pedido
 */
export const getDataDetallePedido = async (idPedido) => {
    return await axios.get(process.env.REACT_APP_PASARELAPEDIDODETALLE + idPedido,config)
    .catch(function (error) {
        if (error.response) {
            return false;
        }
    });
}

/**
 * Metodo para obtener información acerca de un pedido por medio de origen
 * @param {idPedido, origen} getDataPedidoOrigen 
 * @returns object con data de pedido
 */
export const getDataPedidoOrigen = async (pedidoId,origen) => {
    return await axios.get(process.env.REACT_APP_PASARELAPEDIDOORIGEN+"?PedidoId="+pedidoId+"&OrigenId="+origen,config)
    .catch(function (error) {
        if (error.response) {
            return false;
        }
    });
}

/**
 * Metodo para actualizar el estatus de un pedido
 * @param {idLiga, estatus} actualizaLiga 
 * @returns boolean estatus de actualizacion
 */
export const actualizaLiga = async function (idLiga, estatus){
    debugger;
    return await axios.patch(process.env.REACT_APP_LIGASPAGO+"/"+idLiga, [{
      path: "EstatusId",
      op: "replace",
      value: estatus, 
    }],config)
    .catch(function (error) {
      if (error.response) {
        return false;
      }
    });
}

/**
 * Metodo para actualizar todo el registro de la liga
 * @param {obj} updateDataLiga 
 * @returns object con data de liga actualizada
 */
export const updateDataLiga = async (obj) => {
    debugger;
    return await axios.put(process.env.REACT_APP_LIGASPAGO, obj,config)
    .catch(function (error) {
        if (error.response) {
            return false;
        }
    });
}
