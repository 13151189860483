import { React, } from 'react'
import { Row, Col, Grid, Card, ConfigProvider, Result } from 'antd';
import NavBar from './NavBar';
import whatsappico from '../img/whatsapp-icon.png';
import llamadaico from '../img/llamadaicon.png';
import { Footer } from './Footer';

/**
 * Componente para mostrar un error en el pago y/o servicio
 * @param {} Error 
 * @returns vista con diseño de error
 */
function Error() {

  const cardPrincipalStyle = {
    width: '100%',
    boxShadow: '1px 10px 10px 1px rgba(90, 116, 148, 0.3)',
    borderRadius: '0px',
    marginBottom: '30px',
    zIndex: ''
  };

  const cardBodyStyle = {
    width: '100%',
    borderRadius: '0px',
    height: '100%',
  };

  const divresponsive = {
    position: 'relative',
    display: 'grid',
    placeItems: 'center',
    aling: 'center',
    top: '0',
    bottom: '0',
    minHeight: '150px',
    height: '100vh'
  };

  const containerStyle = {
    position: 'absolute',
    maxwidth: '1200px',
    maxHeight: '1456px',
    alingcontent: 'center'
  };

  const rowFooterStyle = {
    bottom: '0px',
    position: 'absolute',
    width: '100%',
    marginTop: '0px'
  };

  const footerStyle = {
    textAlign: 'center',
    color: 'gray',
    position: 'absolute',
    width: '100%',
    marginTop: '30px',
    paddingLeft: '0px',
    paddingRight: '0px',
  };

  const stylesNavBar = {
    navBarHeigth: '70px',
    iconsNavbarWidth: '200px',
    iconsNavbarHeigth: '200px',
    stepsWidth: '25px',
    stepsHeigth: '25px',
    stepsSize: '',
  };

  const divStyle = {
    background: 'linear-gradient(270deg, #1a3992 0%, #e6002b 100%)',
    height: '2px',
    transform: 'matrix(-1, 0, 0, 1, 0, 0)'
  }

  const sucursal = {
    data: [{
      id: 1,
      error: 1
    }]
  };

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  async function responsiveSize(screens) {
    let screennow;
    {
      Object.entries(screens)
        .filter((screen) => !!screen[1])
        .map((screen) => (
          screennow = screen[0]
        ))
    }

    switch (screennow) {
      case "xxl":
        containerStyle.top = '2%';
        stylesNavBar.navBarHeigth = '65px';
        stylesNavBar.iconsNavbarWidth = '200px';
        stylesNavBar.iconsNavbarHeigth = '200px';
        stylesNavBar.stepsSize = 'large';
        stylesNavBar.paddingImg = '0px';
        cardPrincipalStyle.width = '98%';
        divresponsive.minHeight = '360px';
        rowFooterStyle.marginTop = '0px';
        rowFooterStyle.bottom = '0px';

        break;
      case "xl":
        rowFooterStyle.marginTop = '0px';
        rowFooterStyle.bottom = '0px';


        break;
      case "lg":
        rowFooterStyle.marginTop = '0px';
        rowFooterStyle.bottom = '0px';


        break;
      case "md":
        rowFooterStyle.marginTop = '0px';
        rowFooterStyle.bottom = '0px';



        break;
      case "sm":
        rowFooterStyle.marginTop = '0px';
        rowFooterStyle.bottom = '0px';


        break;
      case "xs":
        rowFooterStyle.marginTop = '0px';
        rowFooterStyle.bottom = '0px';

        break;

      default:
        break;
    }
  }

  responsiveSize(screens);

  return (
    <div className="Error">
      <NavBar stylesNavBar={stylesNavBar} sucursal={sucursal} />
      <div style={divStyle}></div>
      <div style={divresponsive}>
        <div className="container" style={containerStyle}>
          <Card style={cardPrincipalStyle} bodyStyle={cardBodyStyle} >
            <div>
              <ConfigProvider
                theme={{
                  token: {
                    colorSuccess: '#ff4d4f',
                    subtitleFontSize: 16
                  },
                  components: {
                    Result: {
                      extraMargin: '0px 0 0 0',
                      subtitleFontSize: 20,
                      colorTextHeading: '#ff4d4f',
                      colorTextDescription: '#3D3D3D'
                    }
                  }
                }}
              >
                <Result
                  status="error"
                  title="¡Este link ha expirado!"
                  subTitle="Para poder realizar tu pago solicita un nuevo acceso
                      con un ejecutivo de nuestro Centro de Atención."

                />
              </ConfigProvider>
            </div>
            <Row style={{ display: 'fkex', alignItems: 'center' }}>
              <Col xs={{ span: 1, offset: 2 }} sm={{ span: 1, offset: 5 }} md={{ span: 1, offset: 6 }} lg={{ span: 1, offset: 8 }} >
                <div style={{ display: 'flex' }}>
                  <img style={{ marginTop: -4 }} src={llamadaico} width={39} height={39}></img>
                  <p style={{ margin: 12, marginTop: 5, color: 'black' }}>3332084440</p>
                </div>
              </Col>
              <Col xs={{ span: 1, offset: 10 }} sm={{ span: 1, offset: 7 }} md={{ span: 1, offset: 7 }} lg={{ span: 1, offset: 3 }} >
                <a href="https://wa.me/3317517975?text=¡Necesito ayuda con mi pago!">
                  <div style={{ display: 'flex' }}>
                    <p style={{ margin: 12, marginTop:5, color: 'black' }}>3317517975</p>
                    <img style={{ marginTop: -4 }} src={whatsappico} width={35} height={35}></img>
                  </div>
                </a>
              </Col>
            </Row>
          </Card>
        </div>

        <div style={rowFooterStyle}>
          <Row >
            <Col xs={{ span: 24, order: 5 }} sm={{ span: 24, order: 5 }} md={{ span: 24, order: 5 }} lg={{ span: 24, order: 5 }} xl={{ span: 24, order: 5 }} xxl={{ span: 24, order: 5 }}>
              <Footer footerStyle={footerStyle}></Footer>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Error;