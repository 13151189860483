import axios from 'axios';

const config = {
    headers: { Authorization: `Bearer ${sessionStorage.getItem("tokenBearer")}` }
};

/**
 * Metodo para obtener información acerca de producto
 * @param {idProducto} getProductosDescripciones 
 * @returns object con data de un producto
 */
export const getProductosDescripciones = async (idProducto) => {
    return await axios.post(process.env.REACT_APP_DESCRIPCIONPRODUCTO,idProducto, config)
    .catch(function (error) {
        if (error.response) {
            
            return false;
        }
    });
}