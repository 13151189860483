import React from 'react';
import { Steps, ConfigProvider } from 'antd';

/**
 * Componente para mostrar los pasos de pago en navbar
 * @param {current,stepsSize} NavBarSteps 
 * @returns vista con diseño de NavBarSteps
 */
export const NavBarSteps = ({ current, stepsSize }) => {

  const steps = [
    {
      title: 'Registrar Tarjeta',
      content: 'First-content',
    },
    {
      title: 'Confirmar Pago',
      content: 'Second-content',
    }
  ];
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: '#f6ffed',
          },
          components: {
            Steps: {
              marginXS: 16
            }
          }
        }}
      >
        <Steps responsive={false} size={stepsSize} current={current} direction="horizontal" items={items} />
      </ConfigProvider>
    </>
  );
};